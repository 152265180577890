export const header = {
  currentTeam: 'Текущая @:featuresTerms.team',
  currentSingleGroupTeam: 'Текущий @:glossary.singleGroupTeam',
  createTeam: 'Новая @:featuresTerms.team',
  uAreAdmin: 'Вы @:featuresTerms.teamAdmin',
  teamSettings: 'Настройки @:featuresTerms.teamR',
  singleGroupTeamSettings: 'Настройки @:glossary.singleGroupTeamR',
  teamProfile: 'профиль @:featuresTerms.teamR',
  singleGroupTeamProfile: 'профиль @:glossary.singleGroupTeamR',
  searchPlaceholder: 'Поиск',
  searchThisChatPlaceholder: 'Поиск в этом чате',

  openChat: 'Перейти в чат',
  hintWarning: 'В поле поиска {verb} быть минимум {min}',
  recentQueries: 'Недавние запросы',
  forNav: 'Для навигации',
  forSelect: 'Для выбора',
  forCancel: 'Для отмены',
  everywhere: 'Везде',
  chatOnly: 'В этом чате',
  inChat: 'В чате',
  disableChatOnly: 'Отключить поиск в этом чате',
  enableChatOnly: 'Включить поиск в этом чате',

  myProfile: 'Мой профиль',
  applications: 'Скачать приложение',
  loginViaQr: 'Вход по QR-коду',
  exit: 'Выйти',

  hideChatsList: 'Скрыть список чатов',
  showChatsList: 'Показать список чатов',

  notificationBar: {
    allowNotification: 'Пожалуйста, нажмите «Разрешить», чтобы включить уведомления.',
    turnOnNotification: 'Включить уведомления',
    notifyQuestion: 'Хотите получать новые сообщения даже когда {app} cкрыт?',
  },
}
