export const meetings = {
  hasMore: 'Еще {n}',
  meetingDialog: {
    title: 'Изменить',
    titleNew: 'Новая встреча',
    titleEditing: 'Изменить',
    meetingTypeSubTitle: 'Участники будут оповещены что встреча будет с аудиозвонком',
    meetingTypeTitle: 'Онлайн встреча',
    cancel: 'Отменить',
    create: 'Создать',
    save: 'Сохранить',
    close: 'Закрыть',
    description: 'Описание...',
    start: 'Начало',
    descriptionErrorMsg: 'Необходимо заполнить описание встречи',
    descriptionErrorMsgLength: 'Превышено максимальное количество символов',
    end: 'Конец',
    hour: '{n} часов | {n} час | {n} часа | {n} часов',
    min: '{min} мин',
    error: {
      title: 'Некорректный временной промежуток',
      description: 'Сделайте дату начала встречи раньше даты конца встречи',
    },
    wishlist: {
      title: 'Собираем пожелания',
      description: 'Пользуетесь ли вы&nbsp;данной возможностью в&nbsp;других календарях? В&nbsp;каких случаях вам необходимо изменить только часть повторяющихся встреч?',
      goTo: 'Рассказать в WishList-е',
    },
    tabs: {
      all: 'Все встречи',
      cell: 'Только эту',
      next: 'Эту и последующие',
    },
    cell: {
      info: 'Пока можно изменить только дату и&nbsp;время отдельной встречи',
    },
  },
  removeDialog: {
    title: 'Удалить встречу',
    titleRepeatable: 'Удалить повторяющуюся встречу {name}',
    description: 'Вы уверены, что хотите удалить встречу',
    descriptionRepeatable: 'В календаре исчезнут все экземпляры встречи и удалится чат',
    remove: 'Удалить',
    cancel: 'Отменить',
    okCaption: 'Удалить всё',
    cancelCaption: 'Не удалять',
  },
  membersSelector: {
    placeholder1: 'Введите имя',
    placeholder2: 'Добавить участников',
  },
  meeting: {
    actions: {
      notification: 'Уведомления',
      counters: 'Счётчик непрочитанных',
      link: 'Скопировать ссылку',
    },
    errorAccessDenied: 'Встреча приватная, доступ есть только у её участников.<br />Добавить во встречу может её создатель',
  },
  rightBar: {
    headerTitle: 'Встречи',
    actions: {
      shortView: 'Компактный вид',
    },
    filters: {
      show: {
        next: 'Предстоящие и текущие',
        title: 'Показывать',
        all: 'Все',
      },
    },
    list: {
      empty: {
        title: 'У вас пока нет встреч',
        subtitle: 'Здесь будут показываться чаты ваших встреч',
      },
      card: {
        actions: {
          pin: 'Закрепить сверху',
          copyLink: 'Скопировать ссылку',
          unpin: 'Открепить',
          edit: 'Изменить',
          delete: 'Удалить',
        },
      },
    },
  },
  fullMeeting: {
    organizer: 'Организатор',
    members: 'Участники',
    createdTitle: 'Создана {date}',
    deadlineTitle: 'Срок {date}',
    repeatability: 'Повторяется ',
  },
  card: {
    repeatabilityDescription: 'Повторяется {repeatability}',
    busy: 'Слот занят',
  },
  repeatability: {
    repeat: 'Повторять',
    byWeek: 'По неделям',
    byDay: 'По дням',
    weekly: {
      every: 'Каждую',
      week: 'неделю',
    },
    daily: {
      every: 'Каждый',
      day: 'день',
    },
    tooltip: 'Это день начала встречи, его нельзя убрать',
    confirm: {
      cancel: 'Не отключать',
      ok: 'Отключить',
      title: 'Отключить повторение встречи {description}?',
      description: 'В календаре исчезнут все будущие экземпляры встречи. ' +
        'Чат и прошедшие экземпляры встречи останутся доступны.',
    },
    1: 'Пн',
    2: 'Вт',
    3: 'Ср',
    4: 'Чт',
    5: 'Пт',
    6: 'Сб',
    7: 'Вс',
  },
  createBtn: {
    tooltip: 'Это календарь другого пользователя. При создании новой встречи этот пользователь автоматически добавится в участники встречи',
  },
}
