export const tasks = {
  from: 'От',
  to: 'Кому',
  assignedToMe: 'Я ответственный',

  openFullForm: 'Открыть в полной форме',
  whatToDo: 'Что нужно сделать?',
  newTaskItemPlaceholder: 'Напишите, что нужно сделать, или добавьте подзадачу, например — #{num}',

  newTaskBtn: 'Создать',
  backToActive: 'Вернуться на вкладку «Активные задачи»',
  taskSearch: 'Поиск по задачам',
  searchEverywhere: 'Искать по всем задачам',
  nothingInTab: 'По вашему запросу во вкладке «{tab}» задач не найдено',

  startTyping: 'Начните ввод...',

  commentsClosed: 'Задача завершена, комментарии закрыты',

  taskIsDone: 'завершена',
  makeOpen: 'Переоткрыть',
  makeDone: 'Завершить',
  edit: 'Изменить',
  delete: 'Удалить',
  closeWindow: '@:common.closeWindow',
  created: 'Создана',
  fromForCreated: 'из',

  dependentTask: 'Надзадача',
  dependentTask_plural: 'Надзадачи',
  dependentTaskTooltip: 'Задачи, зависящие от выполнения этой задачи',
  assignee: 'ответственный',
  member: 'участников | участник | участника | участников',
  member_plural: 'участники',

  notAssigned: 'Не назначен',
  noMembers: 'Нет участников',

  tags: '@:glossary.tag_plural',

  public: 'публичная задача',
  public_tooltip: 'Видна всем участникам @:(featuresTerms.teamR). При создании задачи из сообщения любого чата ссылка на задачу присылается в этот чат',

  hideTaskDescription: 'Свернуть описание задачи',

  remindersTooltip: {
    listTitle: 'Установлены напоминания:',
    howToManage: 'Для изменения откройте редактирование задачи',
  },

  createdFrom: 'Создано из {0} {1}',
  fromMessage: 'сообщения',
  fromDirectMessage: 'личного сообщения',
  createdFromMessageDescription: 'Сообщение от @{name} в {chat}, {date}',
  createdFromMessageInDirectChatDescription: 'Сообщение от @{name} в чате с {chat}',
  createdFromMessageInNonDirectChatDescription: 'Сообщение от @{name} в {chat}',

  pinnedTabs: 'Закреплённые вкладки',
  recentTabs: 'Недавние вкладки',
  pressToPinTip: 'Нажмите на {0} у вкладки из списка недавних ниже, чтобы закрепить её здесь.',
  useFiltersTip: 'Здесь будут появляться недавно использованные фильтры задач. Нажмите на {0}, чтобы воспользоваться фильтрами.',

  taskType: 'Статус задачи',
  typeIncoming: 'Входящие',
  typeAssignedToMe: 'Ответственный',
  typeOutgoing: 'Исходящие',
  typeMember: 'Участник',

  taskStatus: 'Статус задачи',
  statusNew: 'Открытые задачи',
  statusDone: 'Завершённые задачи',

  tabDefault: 'Активные задачи',
  tabIncoming: 'Входящие',
  tabOutgoing: 'Исходящие',
  tabAssigned: 'Задачи, где я ответственный',
  tabDone: 'Архив',

  tabCannotPin: 'Нельзя закрепить больше 5 вкладок',

  people: 'Люди',
  allMembers: 'Включая всех участников',

  errorNotFound: 'Задача не найдена',
  errorAccessDenied: 'Задача приватная, доступ есть только у её участников.<br />Добавить в задачу может её создатель',
  errorUploadingFiles: 'Задача создана, но не все файлы были успешно загружены. Попробуйте ещё раз',
  errorChangingStatus: 'Ошибка изменения статуса задачи. Пожалуйста, попробуйте ещё раз',

  deleteTitle: 'Удалить задачу',
  deleteQuestion: 'Вы уверены, что хотите удалить задачу <b><q>{task}</q></b>?',
  deleteYes: 'Удалить',

  editTask: 'Изменить задачу',
  newTask: 'Новая задача',
  taskCreator: 'Создатель задачи',
  removeAssignee: 'Убрать ответственного',
  toSetAssigneeHTML: 'Чтобы назначить ответственным, перенесите<br>человека из списка участников сюда.',
  myReminders: 'Напоминания',
  noReminders: 'Нет напоминаний',
  clickToAdd: 'Нажмите, чтобы добавить',
  addReminder: 'Добавить напоминание',
  dropFilesTip: 'Перетащите файлы или кликните здесь',
  dropFileTip: 'Отпустите клавишу мыши, чтобы добавить файл к задаче',
  mustHaveDescription: 'У задачи обязательно должно быть описание',

  deadline: {
    basic: 'Срок',
    longer: 'Срок выполнения',
    today: 'Сегодня',
    tomorrow: 'Завтра',
    yesterday: 'Вчера',
    week: 'Неделя',
    no: 'Без срока',
    clickToSet: '(нажмите, чтобы выбрать)',
    overdue: 'Просрочена',
  },

  addTag: 'Добавить тег',
  searchPlaceholder: 'Поиск',
  noneSelected: 'Не выбран',

  searchMembers: 'Введите имя контакта',

  atTime: 'в',

  taskClosed: 'Задача завершена',

  noTasks: 'Нет задач',

  taskItems: {
    title: 'Чек-лист',
    hideCompleted: 'Скрыть завершённые',
    showCompleted: 'Показать завершённые',
    itemDeleted: 'Пункт удалён',
    restore: 'Восстановить',
    close: 'Закрыть',
    addItem: 'Добавить пункт',
    deleteItem: 'Удалить пункт',
    addCheckList: 'Добавить чек-лист',
    networkErr: 'Произошла ошибка. Проверьте соединение с интернетом и попробуйте ещё раз',
    maxLengthItem: 'Текст пункта чеклиста не должен превышать {max} символов',
    unsavedItem: 'Есть несохранённые изменения.',
    unsavedItemEdit: 'Посмотреть изменения',
    unsavedItemDiscard: 'Отменить',
    copySubtask: 'Создать копию задачи',
    captionCreate: 'Создать задачу',
  },

  taskDesk: {
    title: 'Задачи',
    label: 'Доска задач',
  },

  filters: {
    title: 'Фильтры',
    allProjects: 'Все проекты',
    noProject: 'Без проекта',
    noImportance: 'Без приоритета',
    addProject: 'Добавить проект...',
    apply: 'Применить',
    project: 'Проект',
    assignee: 'Исполнитель',
    assignee_plural: 'Исполнители',
    owner: 'Постановщик',
    owner_plural: 'Постановщики',
  },

  createCopy: 'Создать копию',

  importanceCaption: {
    ascending: 'Чем меньше, тем важнее',
    descending: 'Чем больше, тем важнее',
  },

  draft: {
    caption: 'Данные восстановлены из черновика.',
    action: 'Удалить черновик.',
  },

  errorMessages: {
    403: 'Это приватный чат, доступ к переписке есть только у участников чата.<br />Добавить новых участников может администратор чата',
  },
}
