export const calls = {
  callCard: {
    callType: {
      normal: 'Идёт звонок ',
      group: 'Идёт групповой звонок',
    },
  },

  callDetails: {
    info: 'Идёт звонок',
    calling: 'Вызываем',
    reconnecting: 'Переподключаемся...',
    audioRecorded: 'Звонок записывается',
    audioNotRecord: 'Записать звонок',
    callEnded: 'Звонок завершён',

    user_0: 'участник',
    user_1: 'участника',
    user_2: 'участников',

    camDenied: 'Нет доступа к камере',
    micDenied: 'Нет доступа к микрофону',
    bothDenied: 'Нет доступа к микрофону или камере',
    howToEnable: 'Как включить?',
  },

  notifyCallBar: {
    connect: 'Присоединиться',
    accept: 'Принять',
    reject: 'Отклонить',
    cancel: 'Отмена',
    disconnect: 'Отключиться',
    activeCall: 'Идёт звонок',
    initPeer: 'Создание соединения...',
    initMic: 'Запрос разрешения на использование микрофона...',
    processConnection: 'Подключение...',
    waitAnswerState: 'Ожидание ответа от сервера...',
    connected: 'Подключено',
    connectErr: 'Ошибка подключения',
  },

  callAlerts: {
    sureDisconnect: 'Ваше действие прервет текущий звонок. Вы уверены, что хотите продолжить?',
  },

  openChat: 'Открыть чат',
  muteAll: 'Выключить всем микрофон',
  record: {
    start: 'Записать звонок',
    stop: 'Остановить запись',
  },
  mute: {
    on: 'Включить микрофон',
    onFullscreen: 'Включить микрофон — Space',
    off: 'Отключить микрофон',
  },
  volume: {
    on: 'Включить звук',
    off: 'Выключить звук',
  },
  video: {
    on: 'Включить камеру',
    off: 'Выключить камеру',
  },
  screensharing: {
    on: 'Показать экран',
    off: 'Завершить демонстрацию',
  },
  isCalling: 'Вызывает {name}',
  inviteMembers: 'Пригласить участников',
  membersLimit: 'Достигнут лимит участников',
  addMembers: {
    title: 'Добавление участников',
    caption: 'Выбрано {selected} из {of}',
    loadingMembers: 'Загружаем участников',
    noSearchResults: 'Нет результатов',
  },
  tooManyReconnects: 'Звонок завершён: не удалось переподключиться',
  inCall: 'В звонке: {num} из {total}',
  callActionsBtn: {
    alreadyConnected: 'Вы уже подключены к звонку в этом чате',
    activeCallElsewhere: 'Вы уже подключены к звонку в другом чате',
    connectToCall: 'Подключиться к звонку',
    startCall: {
      title: 'Позвонить',
      silent: {
        title: '«Тихий» звонок',
        description: 'Звонок создаётся без вызова каждого участника чата',
      },
      loud: {
        title: '«Громкий» звонок',
        description: 'Каждый участник чата увидит окно вызова его в звонок',
      },
    },
  },
  screenSizes: {
    full: 'Полноэкранный режим',
    middle: 'Средний режим',
    mini: 'Свернутый режим',
  },
  userMediaPreference: {
    audio: 'Только аудио',
    video: 'Только видео',
    both: 'Аудио и видео',
    none: 'Без аудио и видео',
  },
  errors: {
    noMediaAccess: {
      title: 'Не удалось получить доступ к устройству',
      caption: 'Возможно, доступ к микрофону заблокирован или в системе отсутствует устройство выбранного типа.',
    },
  },
  membersLayout: {
    toggleList: 'Участники списком',
    toggleCards: 'Участники карточками',
  },
  lgLayout: {
    toggleGrid: 'Участники мозаикой',
    togglePresenter: 'Режим презентации',
  },
}
