export const globalSearch = {
  all: 'все',
  result_main: 'Результаты поиска',
  result_messages: 'Результаты поиска в сообщениях',
  result_tasks: 'Результаты поиска в задачах',
  result_contacts: 'Результаты поиска в контактах',
  result_groups: 'Результаты поиска в групповых чатах',
  result_files: 'Результаты поиска в файлах',
  params: 'Параметры поиска',

  ftAll: 'Все типы файлов',
  srAll: 'Любой отправитель',
  chatAll: 'Во всех чатах',
  inChat: 'В чате',

  taskType: {
    caption: 'Тип задачи',
    any: 'Любой',
    in: 'Входящие',
    out: 'Исходящие',
    watcher: 'Участник',
  },

  taskStatus: {
    caption: 'Статус задачи',
    any: 'Любой',
    open: 'Активные',
    archive: 'Готово',
  },

  watchersOnly: 'Только участники',
  showArchived: 'Показывать удаленных',
  startTyping: 'Начните ввод...',
}
