export const profiles = {
  unknownUser: 'Неизвестный пользователь',
  unknownGroup: 'Неизвестный чат',
  privateGroup: 'Закрытый канал',
  noRole: 'Должность не указана',
  sendMsgTo: 'Написать',
  addTask: 'Поставить задачу',
  showTasks: 'Показать задачи',
  phone: 'Телефон',
  phoneCaption: 'Контактный телефон, не&nbsp;используется для входа в&nbsp;сервис.<br>Чтобы изменить телефон для входа, напишите в&nbsp;чат «Поддержка»',
  mobile_phone: 'Мобильный телефон',
  company: 'Организация',
  department: 'Подразделение',
  autoDownloadDesktop: 'Автозагрузка файлов',
  notSpecified: 'Не указан',
  theme: 'Цветовая схема',
  language: 'Язык интерфейса',
  removeFromTeam: 'Удалить из @:featuresTerms.teamR',
  lookupFiles: 'Смотреть файлы',
  addToGroup: 'Добавить в групповой чат...',
  addToGroups: {
    caption: 'Добавить {- nameHTML } в групповые чаты',
    searchPlaceholder: 'Найти групповой чат',
    searchEmptyMessage: 'Не найдено ни одного чата',
  },
  kicked: 'Удалён из команды',
  kick: {
    caption: 'Удаление контакта из @:featuresTerms.teamR',
    content: 'Вы уверены, что хотите удалить контакт #[name] из @:featuresTerms.teamR?',
    acceptLabel: 'Удалить',
    acceptError: 'В данный момент удалить контакт невозможно',
  },

  notifications: {
    all: 'Пуш-уведомления',
    error: 'Ошибка изменения параметров уведомлений',
    groups: 'Уведомления групповых чатов',
    tasks: 'Уведомления задач',
    meetings: 'Уведомления встреч',
    captionDisabled: 'Пуш-уведомления отключены для всего сервиса.<br>Включить можно в&nbsp;меню «Мой профиль»',
    systembot: 'Уведомления ТадаБота',
    reactions: 'Уведомления о реакциях',
  },

  groupProfile: 'Профиль чата',
  lookEveryone: 'Смотреть всех',
  makeAdmin: 'Назначить администратором',
  makeMember: 'Снять статус администратора',
  excludeContact: {
    caption: 'Удалить контакт из группового чата',
    content: 'Вы уверены, что хотите удалить контакт #[c] из чата #[g]?',
    acceptLabel: 'Удалить',
    acceptError: 'В данный момент удалить контакт из группового чата невозможно',
  },

  teamProfile: 'профиль @:featuresTerms.teamR',
  singleGroupTeamProfile: 'профиль @:glossary.singleGroupTeamR',
  teamCreator: 'Владелец @:featuresTerms.teamR',
  contactsAmount: 'Всего контактов',
  usage: 'Место на сервере',
  leaveTeam: {
    caption: 'Покинуть @:featuresTerms.teamV',
    content: 'Вы уверены, что хотите покинуть @:featuresTerms.teamV #[name]?',
    acceptLabel: 'Покинуть',
    acceptError: 'В данный момент покинуть @:featuresTerms.teamV невозможно',
    contentOwner: 'Вы являетесь владельцем команды "#[name]", поэтому не можете покинуть её. Владельца команды можно сменить, обратившись в чат Поддержки.',
    acceptLabelSupport: 'Поддержка',
    messageForSupportChat: 'Добрый день! Я являюсь текущим владельцем этой команды. Прошу передать права владения этой командой моему коллеге: ФИО номер телефона',
  },

  leaveGroup: 'Покинуть чат',
  goToChat: 'Перейти в чат',
  removeTeamMember: {
    title: 'Удаление контакта из @:featuresTerms.teamR',
    html: 'Вы уверены, что хотите удалить контакт <b>#[name]</b> из @:featuresTerms.teamR?',
  },

  faviconSetting: 'Использовать аватар @:featuresTerms.teamR как&nbsp;фавиконку',
  autoLaunch: {
    title: 'Автоматически открывать приложение при&nbsp;запуске системы',
  },
  hiddenLaunch: {
    title: 'Запускать приложение свёрнутым',
  },
  logLevel: {
    title: 'Уровень логирования',
    caption: '<b>Trace</b> — максимальный',
  },

  asteriskMentionOption: {
    html: 'Использовать * для упоминаний в&nbsp;чате',
    caption: '@ так же будет работать',
  },

  altSendOption: {
    title: 'Отправка сообщения по&nbsp;{key}+Enter',
    caption: 'Новая строка по Enter',
  },
  resetAllSettingsText: 'Удалить настройки для всех команд',
  resetCurrentTeamSettingsText: 'Удалить настройки для текущей команды',
  setStatus: 'Установить статус',
  changeStatus: 'Сменить статус',
}
