export const calendar = {
  documentTitle: 'Календарь',
  year: 'Год',
  month: 'Месяц',
  week: 'Неделя',
  day: 'День',
  required: 'Обязательные',
  notRequired: 'Не обязательные',
  membersSelector: {
    placeholder: 'Мой календарь',
  },
}
