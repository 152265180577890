export const chatsBar = {
  control: {
    title: 'Чаты',
    menu: {
      groupBadges: 'Счётчики групповых чатов',
      groupNotifications: 'Уведомления групповых чатов',
      shortView: 'Компактный вид',
      sortByName: 'По алфавиту',
    },
  },

  filter: {
    all: 'Все',
    direct: 'Личные',
    group: 'Групповые',
    hidden: 'Архив',
    unreadOnly: 'Непрочитанные',
    comments: 'Комментарии',
  },

  search: {
    placeholder: 'Поиск чата',
    menu: {
      direct: 'Личный чат',
      group: 'Групповой чат',
      join: 'Присоединиться',
      import: 'Импорт из&nbsp;Telegram',
    },
  },

  list: {
    messages: 'Сообщений | Сообщение | Сообщения | Сообщений',
    noMessages: 'Нет сообщений',
    menu: {
      addMembers: 'Добавить участников',
      autocleanup: 'Автоудаление сообщений',
      counter: 'Счётчик непрочитанных',
      hide: 'В архив',
      leave: 'Покинуть чат',
      markAsRead: 'Прочитать чат',
      notifications: 'Уведомления',
      openInNewTab: 'Открыть в отдельном окне',
      pin: 'Закрепить сверху',
      profile: 'Профиль',
      settings: 'Настройки',
      unpin: 'Открепить',
    },
  },

  placeholder: {
    empty: 'Нет&nbsp;чатов',
    emptyHidden: 'Архив&nbsp;пустой',
    emptyUnreadOnly: 'Нет&nbsp;непрочитанных',
    unreadOnlyDone: 'Вы&nbsp;всё прочитали и&nbsp;это замечательно!',
    unreadOnlyEmpty: 'Список чатов пуст, создайте новый и&nbsp;начните общение',
    hiddenHelp: 'Чтобы поместить чат в&nbsp;архив, нажмите на&nbsp;3&nbsp;точки напротив чата и&nbsp;выберите',
    createDirectBtnLabel: 'Создать личный чат',
    createGroupBtnLabel: 'Создать групповой чат',
  },
}
