export const errors = {
  chromeWsErrorHTML: 'Некоторые расширения браузера, работающие с прокси или VPN,<br> могут мешать работе приложения и некоторых других сайтов.<br> Если это сообщение не пропадает, пожалуйста, попробуйте отключить такие расширения.',
  sendingError: 'Ошибка отправки, попробуйте ещё раз',
  implicitError: 'Возникла ошибка, попробуйте позже',
  EMPTY_TOKEN: 'Запрос был отправлен с пустым токеном. Попробуйте выйти из приложения и войти заново.',
  INVALID_TOKEN: 'Запрос отправлен с неправильным токеном. Попробуйте выйти из приложения и войти заново.',
  ACCESS_DENIED: 'Недостаточно прав для операции. Попробуйте обратиться к администратору @:featuresTerms.teamR',
  NOT_FOUND: 'Запрошенные данные не найдены.',
  INVALID_METHOD: 'HTTP-метод не поддерживается.',
  INVALID_DATA: 'Неправильный формат данных.',
  RATE_LIMIT: 'Превышен лимит отправки запросов на сервер. Повторите попытку позднее.',
  INTERNAL_SERVER_ERROR: 'Внутренняя ошибка сервера. Попробуйте обновить страницу. Если проблема повторится, пожалуйста, обратитесь в поддержку {email}',
  unknownError: 'Неизвестная ошибка. Попробуйте обновить страницу. Если проблема повторится, пожалуйста, обратитесь в поддержку {email}',
}
