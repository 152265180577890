export const contacts = {
  title: 'Контакты',
  findContact: 'Найти контакт',
  addDepartment: 'Добавить отдел',
  addContact: 'Добавить контакт',
  createSingleGroupTeam: 'Новый @:glossary.singleGroupTeam',
  emptyDepartment: 'В отделе пока нет контактов',
  noContactsForSearchConditions: 'Нет контактов, удовлетворяющих условию поиска',
  editDepartment: 'Изменить отдел',
  deleteDepartment: 'Удалить отдел',
  deleteText: 'Вы уверены, что хотите удалить отдел',
  yesText: 'Удалить',
  showDeleted: 'Показывать удалённые',
  moveDepartmentHere: 'Переместить отдел сюда',
}
