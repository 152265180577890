export const team = {
  createTeamTitle: 'Создание @:featuresTerms.teamR',
  createFirstTeamTitle: 'Теперь давайте создадим Вашу первую @:featuresTerms.teamV!',
  teamNamePlaceholder: 'Имя @:featuresTerms.teamR',
  teamSettingsChange: {
    part1: 'Позднее данные @:featuresTerms.teamR можно будет',
    part2: 'изменить в настройках',
  },
  updateTeamImage: 'Перетащите сюда изображение или кликните на нём.',
  acceptedImageFormats: 'Поддерживаемые форматы: png, jpg, jpeg, gif',
  createTeamBtn: 'Создать @:featuresTerms.teamV',
  backToTeamListBtn: 'Вернуться к списку @:featuresTerms.teamsV',
  changeProfileBrn: 'Изменить Ваши данные',
  teamBar: {
    rights: 'Вы администратор этой @:featuresTerms.teamR',
    createTeam: 'Создать новую @:featuresTerms.teamV',
    open: 'Открыть',
    create: 'Создать',
  },
  teamManage: {
    caption: 'Управление @:featuresTerms.teamsT',
    containsTeam: '@:featuresTerms.teams, в которых Вы состоите:',
    showBtn: 'Показать все',
    hideBtn: 'Скрыть',
  },
  statistic: {
    statisticFromTeam: 'Статистика команды ',
    month: 'Месяц',
    threeMonths: 'Три месяца',
    halfYear: 'Полгода',
    year: 'Год',
    noDataForPeriod: 'Нет данных за период',
    activeUsers: 'Активных пользователей по сообщениям',
    totalUsers: 'Количество участников в команде',
    newUsers: 'Количество новых пользователей за день',
    newMessages: 'Количество созданных сообщений в день',
    newCalls: 'Количество звонков в день',
    open: 'Открыть статистику',
  },
  errorMessages: {
    403: 'У вас нет доступа к команде. Доступ есть только у участников. Уточните, пожалуйста, информацию о команде у того, кто дал вам ссылку на неё. Если ошибка повторится, напишите, пожалуйста в поддержку — <a href="mailto:{email}">{email}</a>\n<a href="/">Перейти на основной сайт</a> ',
  },
  chatNotSelected: 'Выберите чат и начните общение',
}
