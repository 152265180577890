export const common = {
  nothing: 'Ничего не найдено',

  changeCountry: 'Выберите страну',
  personalAccount: 'Личный кабинет',

  save: 'Сохранить',
  yes: 'Да',
  no: 'Нет',
  add: 'Добавить',
  create: 'Создать',
  retry: 'Попробовать ещё раз',
  cancel: 'Отменить',
  close: 'Закрыть',
  error: 'Ошибка',
  back: 'Назад',
  delete: 'Удалить',
  remove: 'Удалить',
  showAll: 'Показать все',
  move: 'Перейти',
  clear: 'Очистить',
  change: 'Изменить',
  edit: 'Изменить',
  or: 'или',
  processing: 'обработка...',

  description: 'Описание',
  role: 'Должность',
  loading: 'Загрузка...',

  admin: 'Администратор',
  guest: '@.capitalize:featuresTerms.teamGuest',
  teamOwner: '@.capitalize:featuresTerms.teamOwner',
  bot: 'Бот',
  you: 'Вы',

  tutorial: 'Обучение',
  readonly: 'Только для чтения',

  from: 'От',
  to: 'До',
  at: 'в',
  since: 'От даты',
  until: 'До даты',
  today: 'Сегодня',
  yesterday: 'Вчера',

  search: 'Поиск',

  settings: 'настройки',
  globalSettings: 'Глобальные настройки',
  appSettings: 'Настройки приложения',
  debugSettings: 'Настройки для дебага',
  teamSettings: 'Настройки в рамках @:featuresTerms.teamR',
  singleGroupTeamSettings: 'Настройки в рамках @:glossary.singleGroupTeamR',

  groupSettings: 'Настройки группового чата',
  joinGroup: 'Вступить в чат',
  leaveGroup: 'Покинуть чат',
  leaveTeam: 'Покинуть @:featuresTerms.teamV',
  leaveSingleGroupTeam: 'Покинуть @:glossary.singleGroupTeam',
  addMembers: 'Добавить участников',
  removeMember: 'Удалить участника',
  openGroupList: 'Список публичных групповых чатов',
  openProfile: 'Просмотреть профиль',
  addContact: 'Добавить контакт',
  openChat: 'Перейти в чат',
  writeMessage: 'Написать',
  openCalendar: 'Календарь',
  openMyCalendar: 'Мой календарь',
  createTask: 'Поставить задачу',
  callTo: 'Позвонить',

  online: 'В сети',
  offline: 'Не в сети',
  wasOnline: 'Был(-а) в сети {ago}',

  connectionTroubles: 'Проблемы с соединением...',
  tryReconnect: 'Попробовать подключиться снова',

  openTaskDialog: {
    caption: 'Перейти в чат задачи',
    quest: 'Вы уверены, что хотите открыть чат задачи ##[num]?',
  },

  stateLoadMe: 'Загрузка информации о пользователе...',
  stateLoadSettings: 'Загрузка настроек интерфейса...',
  stateLoadRoster: 'Загрузка данных @:(featuresTerms.teamR)...',
  stateLoadTasks: 'Обновление списка задач...',
  stateLoadTime: 'Синхронизация...',

  avaText1: 'Сменить',
  avaText2: 'фото',

  uploadImageText: 'Перетащите сюда изображение или кликните на нем. \n Поддерживаемые форматы: png, jpg, jpeg, gif.',
  uploadImageText2: 'Перетащите сюда изображение или кликните на нем. Поддерживаемые форматы: png, jpg, jpeg, gif.',

  dropDownNotSelected: 'Не выбрано',

  andMore: '...и ещё {left.length}',

  controlKey: 'Ctrl',
  commandKey: 'Cmd',

  canChangeIn: 'Можно изменить в',

  accept: 'Принять',
  name: 'Название',

  day_short: 'дн',
  hour_short: 'ч',
  minute_short: 'мин',

  validationErrors: {
    number: 'Должно быть числом',
    nonNegativeNumber: 'Не может быть меньше 0',
    integer: 'Должно быть целым числом',
    between: 'Должно быть от {from} до {to}',
    maxLength: 'Максимум {to} символов',
  },

  next: 'далее',

  selectFile: 'Выберите файл',
  closeWindow: 'Закрыть окно',
  comingSoon: 'Уже скоро',
  notRequired: 'Не обязательно',
  copied: 'Скопировано',
  contactSupport: 'Написать в поддержку',
  department: 'Отдел',
  project: 'Проект',
  without: 'без',
  pin: 'Закрепить сверху',
  unpin: 'Открепить',
  hide: 'скрыть',
  showMore: 'показать ещё',

  withoutProject: '@:common.without @:glossary.projectR',
  withoutTags: '@:common.without @:glossary.tagR_plural',
  withoutImportance: '@:common.without @:glossary.importanceR',
}
