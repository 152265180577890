export const chatlist = {
  unnamedContact: 'Без имени',
  unnamedTask: 'Без названия',
  unnamedGroup: 'Без названия',
  unnamedMeeting: 'Без названия',
  searchPlaceholder: 'Поиск чата',
  newMessages: 'Новые сообщения',
  noGroupChats: 'Нет ни одного группового чата',
  noDirectChats: 'Нет ни одного личного чата',
  listCollapse: 'Свернуть cписок',
  listExpand: 'Показать все',
  createChat: 'Создать личный чат',
  createGroup: 'Создать групповой чат',
  joinGroup: 'Присоединиться',
  groupChats: 'Групповые чаты',
  directChats: 'Личные чаты',
  appearance: 'Отображение',
  appearanceCompact: 'Компактный вид',
  appearanceOptions: {
    compact: 'Компактное',
    extended: 'Расширенное',
  },
  sorting: 'Сортировка',
  sortingOptions: {
    default: 'По активности',
    name: 'По алфавиту',
  },
  displayUnreadFirst: 'Непрочитанные всегда сверху',
  createDialog: 'Начать диалог',
  createDialogWith: '@:chatlist.createDialog с {name}',
  noMessages: 'Сообщений нет',
  messageDeleted: 'Сообщение удалено',

  writing: 'печатает...',
  writingNY: 'пишет письмо Деду Морозу...',
  writing_plural: 'печатают...',
  writingNY_plural: 'пишут письмо Деду Морозу...',
  you: 'Вы',
  draft: 'Черновик',
  chatMenu: {
    pinChat: '@:common.pin',
    unpinChat: '@:common.unpin',
    hideChat: 'Скрыть этот чат',
  },
}
