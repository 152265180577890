export const teamsBar = {
  expand: 'Развернуть',
  collapse: 'Свернуть',
  newTeam: 'Новая команда',
  menu: {
    dashboard: 'Доска задач',
    dashboardClose: 'Закрыть доску задач',
    calendar: 'Календарь',
    calendarClose: 'Закрыть календарь',
    calendarTooltip: 'Календарь — {n} встреч на этой неделе ' +
      '| Календарь — {n} встреча на этой неделе ' +
      '| Календарь — {n} встречи на этой неделе ' +
      '| Календарь — {n} встреч на этой неделе',
    calendarBlockedTooltip: 'Календарь команды заблокирован',
    chatList: 'Список чатов',
  },
  teamMenu: {
    addContact: 'Добавить контакт',
    integrations: 'Интеграции',
    invite: {
      text: 'Вас пригласили в команду',
      accept: 'Принять',
      decline: 'Отклонить',
    },
    profile: 'Профиль команды',
    settings: 'Настройки',
  },
  help: {
    label: 'Помощь',
    menu: {
      manual: 'Руководство',
      releaseNotes: 'Обновления',
      support: 'Поддержка',
      wishlist: 'Хотелки',
    },
  },
}
