export const modals = {
  commit: 'Сохранить изменения',
  createBtn: 'Создать',
  cancelBtn: 'Отмена',
  closeBtn: 'Закрыть',
  sendBtn: 'Отправить',
  approveBtn: 'Подтвердить',

  NewTask: {
    project: 'Проект',
    noProject: 'Без проекта',
    followers: 'Участники',
    status: 'Статус',
  },
  RedirectMessagesModal: {
    placeholder: 'Добавить сообщение (необязательно)',
    destinationCaption: 'Куда переслать',
    forward: 'Переслать',
  },
  Integrations: {
    title: 'Управление интеграциями',
    connected: 'Подключенные',
    servicesList: 'Список сервисов',
    connect: 'Подключить',
  },
  IntegrationManagement: {
    titleEdit: 'Изменить интеграцию',
    titleAdd: 'Создание интеграции',
    selectChat: 'Сюда будет отправляться информация',
    comment: 'Описание',
    isEnabled: 'Включено',
    manual: 'Инструкция по настройке',
    deleteBtn: 'Удалить интеграцию',
    deleteTitle: 'Удалить интеграцию',
    deleteText: 'Вы уверены, что хотите удалить интеграцию «{name}» из группового чата «{group}»?',
  },
  CalendarIntegrations: {
    title: 'Интеграция с календарями',
  },
  SectionModal: {
    new: 'Новый отдел',
    create: 'Создание отдела',
    edit: 'Изменить отдел',
    name: 'Название отдела',
    priority: 'Приоритет',
    tip: 'Перетащите с помощью мыши или используйте клавиши',
    createError: 'Возникли проблемы с созданием отдела',
    editError: 'Возникли проблемы с изменением отдела',
  },
  EditContact: {
    caption: 'Изменить профиль',
    toSection: 'Отдел',
    giveRights: 'Назначить администратором',
    fallbackError: 'Возникли проблемы с изменением профиля',
    guest: '@:featuresTerms.teamGuest',
    member: '@:featuresTerms.teamMember',
    admin: '@:featuresTerms.teamAdmin',
    owner: '@:featuresTerms.teamOwner',
  },
  Dialog: {
    logout: {
      caption: 'Выход из учетной записи',
      content: 'Вы уверены, что хотите выйти?',
      acceptLabel: 'Выйти',
    },
    leaveGroup: {
      caption: 'Покинуть чат',
      content: 'Вы уверены, что хотите покинуть групповой чат #[name]?',
      acceptLabel: 'Покинуть',
      acceptError: 'В данный момент покинуть групповой чат невозможно',
    },
  },
  CreationTeam: {
    caption: 'Новая @:featuresTerms.team',
    captionSingleGroupTeam: 'Новый @:glossary.singleGroupTeam',
    updateImage: 'Перетащите сюда изображение или кликните на нем.\nПоддерживаемые форматы: png, jpg, jpeg, gif.',
    teamNamePlaceholder: 'Имя @:featuresTerms.teamR',
    singleGroupTeamNamePlaceholder: 'Название чата',
    teamMembersAddTitle: 'Пригласить участников',
    teamMembersAddDesc: 'Начните вводить данные и новая форма добавления участника появится автоматически',
    createBtn: 'Создать',
    createTeamError: 'Возникли проблемы с созданием @:featuresTerms.teamR',
    uploadLogoTeamError: 'Возникли проблемы с изменением логотипа @:featuresTerms.teamR',
    deleteLogoTeamError: 'Возникли проблемы с удалением логотипа @:featuresTerms.teamR',
    contactsSelectLabel: 'Выберите контакты из других ваших команд',
    addByPhoneNumberOptionLabel: 'Или добавьте по номеру телефона',
  },
  TeamSettings: {
    caption: 'Настройки @:featuresTerms.teamR',
    captionSingleGroupTeam: 'Настройки @:glossary.singleGroupTeamR',
    teamName: 'Название @:featuresTerms.teamR',
    singleGroupTeamName: 'Название чата',
    adminTitle: 'Администраторы',
    ownerTitleWithName: 'Владелец: {name}',
    adminAddBtn: 'Добавить администраторов',
    editMsgSetting: {
      caption: 'Редактирование и&nbsp;удаление&nbsp;сообщений в&nbsp;@:featuresTerms.teamP',
      disabled: 'Недоступно',
      oneHour: '1 час с момента создания',
      oneDay: '1 день с момента создания',
      noRestrictions: 'Без ограничений',
      hint: {
        restricted: '@:glossary.member_plural могут удалять и редактировать свои сообщения в течение указанного срока.',
        unrestricted: '@:featuresTerms.teamAdmins всегда могут удалять любые сообщения.',
      },
    },
    deleteCommand: 'Удалить @:featuresTerms.teamV',
    deleteCommandSingleGroup: 'Удалить чат',
    deadlineTime: 'Время срока исполнения задач по умолчанию',
    deleteDlg: {
      caption: 'Удаление @:featuresTerms.teamR',
      content: 'Вы уверены, что хотите удалить @:featuresTerms.teamV #[name]?',
      acceptLbl: 'Удалить',
      acceptErr: 'В данный момент удалить @:featuresTerms.teamV невозможно',
    },
    createTeamErr: 'Возникли проблемы с изменением @:featuresTerms.teamR',
    uploadLogoTeamErr: 'Возникли проблемы с изменением логотипа @:featuresTerms.teamR',
    deleteLogoTeamErr: 'Возникли проблемы с удалением логотипа @:featuresTerms.teamR',
    changeContactStatusErr: 'Возникли проблемы с изменением статуса контакта {name}',
    notFound: 'Не найдено ни одного контакта',
    pushEnterToSelect: 'Для того, чтобы выбрать контакт, нажмите',
    tabs: {
      general: 'Общие',
      tasks: '@:glossary.task_plural',
    },
    experimentalSettings: {
      title: 'Экспериментальные настройки',
      caption: 'Страница перезагрузится для применения изменённых настроек',
    },
    useComplexity: 'Использовать сложность',
    useImportance: 'Использовать приоритет',
    extraTasksStatuses: 'Дополнительные статусы задач',
    changeViaSupport: 'Настраивается через поддержку',
  },
  UserProfile: {
    caption: 'Изменить профиль',
    name: 'Имя',
    lastName: 'Фамилия',
    patronymicName: 'Отчество',
    role: 'Должность',
    phone: 'Телефон',
    phoneCaption: 'Контактный телефон, не&nbsp;используется для входа в&nbsp;сервис.<br>Чтобы изменить телефон для входа, напишите в&nbsp;чат «Поддержка»',
    email: 'E-mail',
    uploadText: {
      part1: 'Перетащите сюда изображение или кликните на нем.',
      part2: 'Поддерживаемые форматы:',
      part3: 'png, jpg, jpeg, gif.',
    },
    saveErr: 'Возникли проблемы с изменением статуса контакта {name}',
    uploadAvatarErr: 'Возникли проблемы с изменением аватара',
    deleteAvatarErr: 'Возникли проблемы с удалением аватара',
  },
  CountryCode: {
    caption: 'Выберите страну',
    searchInput: 'Поиск',
    notFound: 'Ничего не найдено',
    close: 'Закрыть',
  },
  allObservers: {
    caption: 'Добавить участников',
    searchPlaceholder: 'Найти человека',
    zeroObservers: 'Нет участников',
    notFoundObservers: 'Участники не найдены',
    contactNotFound: 'Контакты не найдены',
    badgeAdmin: 'Администратор',
    badgeMeetingAdmin: 'Организатор',
    badgeOwner: 'Создатель',
    badgeAssignee: 'Ответственный',
    youBadge: 'Это вы',
    retryBtn: 'Попробовать снова',
    cancelBtn: 'Отмена',
    addBtn: 'Добавить',
    closeBtn: 'Закрыть',
    addUsersBtn: 'Добавить участников',
    user: 'участников | участник | участника | участников',
    errorLoadingMembers: 'Ошибка загрузки списка участников, попробуйте снова',
    taskTitle: 'задаче',
    stdErr: 'Произошла ошибка, повторите попытку позже',
    setAssignee: 'Назначить ответственным',
    unsetAssignee: 'Снять статус ответственного',
    removeTaskMember: {
      name: 'Удалить участника',
      title: 'Удалить участника из задачи',
      text: {
        part1: 'Вы уверены, что хотите удалить контакт',
        part2: 'из группового чата',
      },
      yesText: 'Удалить',
    },
    setAdmin: 'Назначить администратором',
    unsetAdmin: 'Снять статус администратора',
    removeMember: {
      name: 'Удалить участника',
      title: 'Удалить контакт из чата',
      text: {
        part1: 'Вы уверены, что хотите удалить контакт',
        part2: 'из группового чата',
      },
      yesText: 'Удалить',
    },
    showProfile: 'Показать профиль',
    in: 'в',
  },
  UploadFile: {
    caption: 'Загрузка файлов',
    cancelBtn: 'Отмена',
    file: 'файлов | файл | файла | файлов',
  },
  AvatarSelector: {
    delete: 'Удалить аватар',
    uploadText: {
      part1: 'Перетащите сюда изображение или кликните на нем.',
      part2: 'Поддерживаемые форматы:',
      part3: 'png, jpg, jpeg, gif.',
    },
    avatar: 'Аватар',
    ofContact: 'контакта',
    ofChat: 'чата',
    ofTeam: '@:featuresTerms.teamR',
  },
  AddContact: {
    firstName: 'Имя',
    lastName: 'Фамилия',
    patronymicName: 'Отчество',
    telNeed: 'Телефон (обязательно)',
    tel: 'Телефон',
    caption: 'Добавление контактов',
    addAnotherContact: 'Добавить еще контакт',
    contactNumber: 'Контакт {number}',
    captionSingleGroupTeam: 'Приглашение в @:glossary.singleGroupTeamV',
    addingTip: 'Начните вводить данные и новая форма добавления участника появится автоматически',
    cancelBtn: 'Отмена',
    availableSlotsTooltip: 'Занято {busy} из {available}',
    noAvailableSlotsTooltip: 'Заняты все рабочие места. Обратитесь к владельцу команды, чтобы добавить еще',
    invalidPhoneFormat: 'Неверный формат телефона',
    inviteBtn: 'Отправить приглашение',
    addGroupChat: 'Добавить групповые чаты',
    stdErr: 'Произошла ошибка, повторите попытку позже',
    toSection: 'Отдел',
    noSection: 'Без отдела',
    isAdmin: 'Администратор',
    newContactsWillAutoAdd: '<b>Новые контакты автоматически добавятся в группы</b>',
    addMoreGroups: 'Добавить ещё групповые чаты...',
    useLinkOrQR: 'Воспользуйтесь ссылкой или QR-кодом-приглашением',
    downloading: 'Загрузка...',
    copyLinkBtn: 'Скопировать ссылку',
    printQRBtn: 'Распечатать QR-код',
    showQR: 'Дайте отсканировать этот код коллеге',
    createInviteLink: 'Создайте ссылку-приглашение',
    sendInviteLink: 'И отправьте ссылку или qr-код коллеге для приглашения в команду',
    deleteInviteLinkBtn: 'Удалить ссылку',
    createInviteLinkBtn: 'Сгенирировать ссылку',
    role: 'Роль',
    byPhone: 'По телефону',
    otherTeams: 'Из других команд',
    otherTeamsCaption: 'Пользователи могут принадлежать к разным Личным кабинетам владельцев команд. От этого зависит, займет приглашенный пользователь свободное рабочее место или нет',
    QRCode: 'Ссылка-приглашение',
    noAvailablePlaces: {
      caption: 'В этой команде не осталось свободных рабочих мест',
      advise: 'Если вы владелец команды, вы можете добавить рабочие места в вашем Личном кабинете',
    },
    fromNodes: {
      title: 'Федерация',
      loadUsersError: 'Ошибка загрузки пользователей.',
      contactSelectLabel: 'Выберите пользователей из Федерации',
    },
    directImport: {
      title: 'Из Excel',
      step1: {
        action: 'Добавить {number}',
        title: 'Загрузка',
        downloadExample: 'Скачайте шаблон заполнения...',
        downloadExampleBtn: 'Скачать шаблон',
        send: '...а потом перетащите документ сюда',
        or: 'или',
        sendBtn: 'Загрузите файл',
        checkData: 'Проверить данные',
        cancelUpload: 'Отменить загрузку',
      },
      step2: {
        title: 'Проверка',
        nothingFound: {
          title: 'Ничего не нашлось',
          caption: 'В загруженном файле не удалось найти подходящие данные',
        },
        selectAllCheckbox: 'Выбрать все',
      },
      step3: {
        action: 'Остановить добавление',
        importStarted: 'Импорт данных начался',
        importFinished: 'Импорт данных завершен',
        captionProcessing: 'Новые данные будут появляться в @:featuresTerms.teamP по мере обработки на сервере',
        captionDone: 'Новые данные уже доступны в @:featuresTerms.teamP',
        mayCloseModal: 'Вы можете закрыть это окно',
        hasErrors: 'Ошибки в процессе импорта: {numErrors}',
        noErrors: 'Ошибок в процессе импорта не обнаружено',
      },
      step4: {
        action: 'Готово',
      },
    },
  },
  InvitationModal: {
    title: 'Приглашение в @:featuresTerms.teamV',
    text: 'Вас пригласили в @:featuresTerms.teamV',
    yesText: 'Присоединиться',
  },
  Contact: {
    firstName: 'Имя',
    lastName: 'Фамилия',
    patronymicName: 'Отчество',
    role: 'Должность',
    status: 'Статус',
    section: 'Отдел',
  },
  EntityGenericList: {
    caption: 'Список',
    searchPlaceholder: 'Найти',
    searchEmptyMessage: 'Ничего не найдено',
    rejectLabel: 'Закрыть',
  },
  AvatarCropper: {
    caption: 'Выберите видимую область',
  },
  TextUploaderModal: {
    caption: 'Создать блок текста',
  },
  FileUploader: {
    file_0: 'файл',
    file_1: 'файла',
    file_2: 'файлов',
    dropzoneDesc: 'Перетащите сюда файлы или кликните на этой области.',
    fileSizeLimit: 'Максимальный размер загружаемого файла — {sizeLimit}Мб.',
    fileOverSize: 'Файл {names} больше максимального размера загружаемого файла {limit}Мб',
    filesOverSize: 'Файлы  {names} больше максимального размера загружаемого файла {limit}Мб',
    fileTitle: 'Файл | Файл | Файлы | Файлы',
    fileError: 'не отправится, потому что не отвечает настройкам безопасности сервера | ' +
        'не отправится, потому что не отвечает настройкам безопасности сервера | ' +
        'не отправятся, потому что не отвечают настройкам безопасности сервера | ' +
        'не отправятся, потому что не отвечают настройкам безопасности сервера',
  },
  GroupInstanceRow: {
    joinGroup: 'Вступить',
    leaveGroup: 'Покинуть',
    user: 'участников | участник | участника | участников',
  },
  IntegrationHelp: {
    help: 'Помощь',
  },
  MemoryUsage: {
    caption: 'Динамика использования места на сервере',
    on: 'На',
    sixMonth: '6 месяцев',
    oneYear: '1 год',
    allTime: 'Все время',
    details: 'Динамика использования',
    usageText: 'из {limit} используется',
  },
  GroupForm: {
    create: {
      caption: 'Создание нового группового чата',
      saveBtn: 'Создать чат',
    },
    edit: {
      caption: 'Настройки чата',
      saveBtn: 'Сохранить',
    },
    namePlaceholder: 'Название',
    descPlaceholder: 'Описание',
    groupMembers: 'Участники',
    commonPublic: 'Публичный групповой чат',
    defaultForAll: 'Автоматически добавлять новых участников',
    readOnlyForMembers: 'Могут писать только администраторы',
    errors: {
      create: 'Возникли проблемы с созданием группового чата',
      edit: 'Возникли проблемы с изменением группового чата',
      setIcon: 'Возникли проблемы с изменением логотипа группового чата',
      deleteIcon: 'Возникли проблемы с удалением логотипа группового чата',
    },
    deleteDialog: {
      caption: 'Удаление группового чата',
      content: 'Вы уверены, что хотите удалить групповой чат #[name]?',
      acceptLabel: 'Удалить',
      acceptError: 'В данный момент групповой чат невозможно удалить',
    },
  },
  GroupJoin: {
    caption: 'Список публичных групповых чатов',
    searchPlaceholder: 'Найти групповой чат',
    notFound: 'Не найдено ни одного группового чата',
  },
  MobileApps: {
    caption: 'Скачать приложение',
    desktopApp: 'Для компьютера',
    mobileApp: 'Для телефона',
    freshAndroid: 'Тестовая сборка',
    version: 'версия',
    loading: 'загрузка...',
  },
  MobileLogin: {
    qrLogin: 'Вход в приложение без смс',
    qrGuide: [
      'Запустите мобильное приложение',
      'На экране входа нажмите на иконку QR-кода в правом верхнем углу',
      'Отсканируйте QR-код',
    ],
  },
  EntitySelector: {
    findHuman: 'Найти человека',
    addContact: 'Добавить контакт',
    inviteMembers: 'Пригласить участников',
    invite: 'Пригласить',
    cancel: 'Отмена',
    limitReached: 'Выбрано максимальное количество участников!',
  },
  EditSection: {
    edit: 'Изменить проект',
    add: 'Добавить проект',
    name: 'Название',
    description: 'Проекты видят все участники команды',
    deleteTitle: 'Удаление проекта',
    deleteText: 'Вы уверены, что хотите удалить проект <b>{name}</b>?',
  },
  FileBrowser: {
    title: 'Файлы',
    search: 'Поиск',
    noResults: 'Ничего не нашлось',
    searchEverywhere: 'Во всех чатах',
    searchIn: 'В чатe:',
    allFileTypes: 'Все типы файлов',
    typeImage: 'Изображения',
    typeVideo: 'Видео',
    typeFile: 'Документы',
    typeVoice: 'Голосовые сообщения',
    fromMe: 'Вы',
    toMe: 'Мне',
    download: 'Скачать',
    forward: 'Переслать',
    empty: 'Список пуст',
    contacts: 'Контакты',
    groups: 'Групповые чаты',
  },
  AutodeleteSettings: {
    title: 'Автоудаление сообщений',
  },
  ImportTasks: {
    title: 'Импорт задач',
    caption: 'Вы можете импортировать задачи в @:featuresTerms.teamV одним файлом',
    step1: {
      title: 'Загрузка',
      xlsx: {
        title: 'Импорт из Excel',
        getTemplate: 'Скачайте и заполните образец',
        uploadTemplate: 'Загрузите образец',
      },
      trello: {
        title: 'Импорт из Trello',
        exportTasks: 'Экспортируйте задачи из Trello',
        howToExport: '1. Меню<br />2. Ещё<br />3. Печать и экспорт...<br />4. Экспортировать как JSON',
        uploadTemplate: 'Загрузите JSON',
      },
    },
    step2: {
      title: 'Проверка',
      btnLabel: 'Импортировать',
      nothingFound: {
        title: 'Ничего не нашлось',
        caption: 'В загруженном файле не удалось найти подходящие данные',
      },
    },
    step3: {
      title: 'Готово',
      importStarted: 'Импорт данных начался',
      importFinished: 'Импорт данных завершен',
      captionProcessing: 'Новые данные будут появляться в @:featuresTerms.teamP по мере обработки на сервере',
      captionDone: 'Новые данные уже доступны в @:featuresTerms.teamP',
      mayCloseModal: 'Вы можете закрыть это окно',
      hasErrors: 'Ошибки в процессе импорта: {numErrors}',
      noErrors: 'Ошибок в процессе импорта не обнаружено',
    },
  },
  ExportModal: {
    title: 'Экспорт задач',
    downloadAllTasks: 'Скачать все задачи',
    inFormat: 'в формате Excel',
  },
  TasksColorsRules: {
    buttonTitle: 'Правила оформления',
    header: 'Правила оформления задач',
    priority: {
      label: 'Приоритет',
      tooltip: 'Задаче будет присвоен цвет того правила, чей приоритет выше.',
    },
    newRule: 'Новое правило',
    rule: {
      description: 'Название',
      project: '@:common.project',
      tags: '@:glossary.tag_plural',
      colors: 'Цвет выделения',
      status: 'Статус',
      importance: '@:glossary.importance',
      complexity: '@:glossary.complexity',
      urgency: '@:glossary.urgency',
    },
    notAuthorized: 'Изменять и добавлять новые правила может @:featuresTerms.teamAdmin',
    noRules: 'Пока нет ни одного правила',
  },
}
