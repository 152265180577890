export const calendarIntegrations = {
  description_1: 'Это ссылка на ваши задачи с&nbsp;дедлайнами и&nbsp;встречи в&nbsp;<code>tada.team</code> для добавления в&nbsp;сервисы календарей.',
  description_2: 'Скопируйте её и&nbsp;вставьте в&nbsp;календарь сервиса, в&nbsp;котором хотите увидеть задачи и&nbsp;встречи из&nbsp;<code>tada.team</code>',
  description_3: 'Периодичность синхронизации Яндекс определяет автоматически.<br>Она может составлять от нескольких часов до двух дней.',
  linkHint: 'Скопировать ссылку',
  copied: 'Скопировано',
  Yandex: {
    file_1: '/assets/i/calendar/yandex/01.png',
    img_caption_1: 'Авторизуйтесь в&nbsp;<a href="https://calendar.yandex.ru" target="_blank">Яндекс.Календаре</a>. В левом столбце найдите «Подписки» и&nbsp;нажмите «+&nbsp;Новая&nbsp;подписка»',
    img_alt_1: 'Интерфейс Яндекс.Календаря',
    file_2: '/assets/i/calendar/yandex/02.png',
    img_caption_2: 'Вставьте вашу ссылку в&nbsp;поле&nbsp;«Адрес календаря». Придумайте название, настройте цвет и&nbsp;уведомления. Нажмите «Создать» в&nbsp;правом нижнем углу',
    img_alt_2: 'Интерфейс новой подписки на календарь',
    file_3: '/assets/i/calendar/yandex/03.png',
    img_caption_3: 'Задачи и встречи из&nbsp;<code style="font-size:100%;">tada.team</code> появятся в&nbsp;течение нескольких минут',
    img_alt_3: 'Сообщение о&nbsp;добавлении событий',
    file_4: '/assets/i/calendar/yandex/04.png',
    img_caption_4: 'Задачи и&nbsp;встречи из&nbsp;tada.team появились в&nbsp;Яндекс.Календаре',
    img_alt_4: 'Яндекс.Календарь с&nbsp;задачами и&nbsp;встречами из&nbsp;tada.team',
  },
  Google: {
    file_1: '/assets/i/calendar/google/01.png',
    img_caption_1: 'Авторизуйтесь в&nbsp;<a href="https://calendar.google.com/" target="_blank">Гугл-календаре</a>. В&nbsp;левом столбце найдите «Другие календари»</br>и&nbsp;нажмите&nbsp;«<span style="font-size:16px;">+</span>». В&nbsp;появившемся меню выберите пункт «Добавить&nbsp;по&nbsp;URL»',
    img_alt_1: 'Интерфейс Гугл-Календаря',
    file_2: '/assets/i/calendar/google/02.png',
    img_caption_2: 'Вставьте вашу ссылку в&nbsp;«URL&nbsp;календаря» и&nbsp;нажмите кнопку «Добавить&nbsp;календарь»',
    img_alt_2: 'Интерфейс подписки на новый календарь',
    file_3: '/assets/i/calendar/google/03.png',
    img_caption_3: 'Календарь добавлен. Задачи и&nbsp;встречи из&nbsp;<code style="font-size:100%;">tada.team</code> появятся в&nbsp;течение нескольких минут. <br> <span style="color:red;">События из&nbsp;любых сервисов, созданные уже после импорта календаря в&nbsp;гугл, появляются&nbsp;в&nbsp;нём с&nbsp;задержкой&nbsp;в&nbsp;несколько часов</span>',
    img_alt_3: 'Сообщение о&nbsp;добавлении календаря',
    file_4: '/assets/i/calendar/google/04.png',
    img_caption_4: 'Задачи из&nbsp;tada.team появились в&nbsp;Гугл-календаре',
    img_alt_4: 'Гугл-календарь с&nbsp;задачами и&nbsp;встречами из&nbsp;tada.team',
  },
  iCalendar: {
    img_caption_1: 'Откройте iCalendar. В&nbsp;меню «Файл» выберите «Новая подписка на&nbsp;календарь...»',
    img_alt_1: 'Интерфейс iCalendar',
    img_caption_2: 'Вставьте вашу ссылку в&nbsp;«URL-адрес календаря» и&nbsp;нажмите «Подписаться»',
    img_alt_2: 'Окно подписки на&nbsp;новый календарь',
    img_caption_3: 'Назовите новый календарь. Настройте уведомления и&nbsp;период обновления. Нажмите&nbsp;«ОК».&nbsp;Задачи и&nbsp;встречи появятся&nbsp;в&nbsp;течение нескольких минут',
    img_alt_3: 'Окно настройки календаря',
  },
}
