export const importChat = {
  title: 'Импорт группового чата из&nbsp;Telegram',
  closeTitle: 'Закрыть и вернуться позже',
  open: 'Импорт из&nbsp;Telegram',
  errorCaption: 'Ошибка',
  steps: {
    load: 'Загрузка файла',
    check: 'Проверка файла',
    import: 'Сопоставление пользователей',
    done: 'Создание чата',
  },
  step1: {
    processLabel: '{loadingSize} из {fileSize}',
    description: {
      tooltip: 'Для macOS скачайте и установите Telegram с официального сайта разработчика — ' +
          'экспорт чата доступен только в этой версии',
      title: 'Сначала выгрузите групповой чат из&nbsp;Telegram',
      1: 'Зайдите в&nbsp;Telegram-е в&nbsp;групповой чат, который хотите перенести в&nbsp;tada.team',
      2: 'Нажмите на&nbsp;иконку «ещё» справа сверху',
      3: 'Выберите «Экспорт истории чата»',
      4: 'Выберите формат экспорта — <b>Машиночитаемый JSON</b>',
      5: 'Нажмите «Экспортировать» — сохраниться папка с данными вида «ChatExport_2022-MM-DD»',
      6: 'Проверьте, что в&nbsp;папке обязательно содержится файл result.json и&nbsp;опционально подпапки с&nbsp;документами',
      7: 'Запакуйте папку «ChatExport_2022-MM-DD» в&nbsp;один <b>.zip</b> архив',
    },
    upload: {
      text: '...затем перетащите .zip архив сюда',
      textError: 'Это не .zip',
      textCountError: 'Вы добавили больше 1 файла',
      or: 'или',
      warning: 'Мы принимаем только .zip формат',
      countWarning: 'Можно загружать только 1 zip-файл',
      load: 'Загрузите файл',
      repeatLoad: 'Загрузить заново',
      loading: {
        title: 'Загружаем файл на сервер. Не закрывайте окно, пожалуйста, иначе придётся начать сначала',
        titleDone: 'Файл загружен на сервер',
        titleError: 'Файл не загружен',
      },
      remove: 'Удалить файл',
      cancel: 'Отменить загрузку',
    },
    checkData: 'Проверить данные',
  },
  step2: {
    title: 'Архив {fileName} распакововывается и проверяется',
    description: 'Может занять от&nbsp;нескольких минут до&nbsp;часов <br/>' +
        'Вы можете закрыть окно и&nbsp;вернуться позже.<br/>' +
        'Все данные сохранятся',
    titleError: 'В архиве нет json формата',
    descriptionError: 'Посмотрите инструкции ещё раз, мы принимаем только json формат файлов',
    titleDone: 'Архив распакован и проверен',
    descriptionDone: '',
    cancelImport: 'Отменить импорт',
    next: 'Дальше',
    repeat: 'Начать заново',
  },
  step3: {
    info: 'Необходимо сопоставить пользователей из&nbsp;Telegram и&nbsp;tada.team для правильного отображения истории чата. </br>' +
        'Если пользователя нет в&nbsp;tada.team – добавьте его в&nbsp;команду в&nbsp;ActiveDirectory или через интерфейс tada.team <br/>' +
        'Окно можно закрыть и вернуться к&nbsp;сопоставлению после добавления пользователей',
    errorCaption: 'Ошибка',
    back: 'Назад',
    repeat: 'Повторить ещё раз',
    chatInfo: {
      name: 'Название чата',
      public: 'Это приватный чат',
      info: 'Вы сможете изменить публичность в настройках чата после завершения импорта',
    },
    generation: {
      title: 'Создаём чат',
      description: 'Процесс займёт от нескольких минут до часа',
    },
    users: {
      placeholder: 'Пользователь',
      nik: 'Никнейм из Telegram',
      id: 'ID из Telegram',
      admin: 'Админ чата',
      contact: 'Имя в tada.team',
    },
    processedLabel: '{count} из {total}',
    errorLabel: 'Вы выбрали {count} {name}',
  },
  step4: {
    title: 'Групповой чат {chat} добавлен',
    description: 'Изменить настройки можно в основном интерфейсе tada.team',
    goTo: 'Перейти в чат',
    nextImport: 'Импортировать другой чат',
  },
}
