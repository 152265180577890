export const glossary = {
  everything: 'все',

  contact_plural: 'контакты',
  contact: 'контакт',
  contactR: 'контакта',
  contactD: 'контакту',
  contactV: 'контакт',
  contactT: 'контактом',
  contactP: 'контакту',

  contacts: 'контакты',
  contactsR: 'контактов',
  contactsD: 'контактам',
  contactsV: 'контакты',
  contactsT: 'контактами',
  contactsP: 'контактах',

  free_male_R: 'свободного',
  free_plural_R: 'свободных',

  workingPlace_plural_R: 'рабочих мест',
  workingPlace_plural_D: 'рабочих места',

  workingPlace: 'рабочее место',
  workingPlace_R: 'рабочего места',

  task: 'задача',
  task_plural: 'задачи',

  meetings_plural: 'Встречи',

  file: 'файл',
  file_plural: 'файлы',

  group: 'групповой чат',
  group_plural: 'групповые чаты',
  groupNew: 'групповой чат',
  groupNew_plural: 'групповые чаты',
  groupNewV: 'групповой чат',

  notification: 'уведомление',
  notifications: 'уведомления',

  people: 'Люди',

  match: 'совпадений | совпадение | совпадения | совпадений',
  should: 'должно | должен | должно',
  character: '{n} символов | {n} символ | {n} символа | {n} символов',

  message: 'сообщений | сообщение | сообщения | сообщений',
  message_count: '{n} сообщений | {n} сообщение | {n} сообщения | {n} сообщений',

  man: 'человек | человек | человека | человек',

  sender: 'отправитель',

  member: 'участников | участник | участника | участников',
  member_plural: 'участники',

  image: 'изображение',
  profile: 'Профиль',

  youAre: 'Это Вы',

  formatting: 'форматирование',
  editing: 'изменить',
  cancel: 'отменить',
  send: 'отправить',
  close: 'закрыть',

  owner: 'владелец',
  ownerR: 'владельца',
  ownerV: 'владельца',
  owner_plural: 'владельцы',
  admin: 'администратор',
  admins: 'администраторы',

  team: 'команда',
  teamR: 'команды',
  teamD: 'команде',
  teamV: 'команду',
  teamT: 'командой',
  teamP: 'команде',

  teams: 'команды',
  teamsR: 'команд',
  teamsD: 'командам',
  teamsV: 'команды',
  teamsT: 'командами',
  teamsP: 'командах',

  chat: 'чат',
  chatR: 'чата',
  chatD: 'чату',
  chatV: 'чат',
  chatT: 'чатом',
  chatP: 'чате',

  singleGroupTeam: 'персональный @:glossary.chat',
  singleGroupTeamR: 'персонального @:glossary.chatR',
  singleGroupTeamD: 'персональному @:glossary.chatD',
  singleGroupTeamV: 'персональный @:glossary.chatV',
  singleGroupTeamT: 'персональным @:glossary.chatT',
  singleGroupTeamP: 'персональном @:glossary.chatP',

  teamOwner: '@:glossary.owner @:glossary.teamR',
  teamAdmin: '@:glossary.admin @:glossary.teamR',

  teamAdmins: '@:glossary.admins @:glossary.teamR',
  teamMembers: '@:glossary.members @:glossary.teamR',

  sticker: 'Стикер',
  sticker_plural: 'Стикеры',

  project: 'проект',
  projectR: 'проекта',
  projectV: 'проект',
  project_plural: 'проекты',

  tag: 'тег',
  tagR: 'тега',
  tagV: 'тег',
  tag_plural: 'теги',
  tagR_plural: 'тегов',
  tagV_plural: 'теги',

  assignee: 'исполнитель',
  assigneeR: 'исполнителя',
  assigneeV: 'исполнителя',
  assignee_plural: 'исполнители',

  status: 'статус',
  statusR: 'статуса',
  statusV: 'статус',
  status_plural: 'статусы',
  importance: 'приоритет',
  importanceR: 'приоритета',
  importance_short: 'п',
  importance_lowest: 'очень низкий',
  importance_low: 'низкий',
  importance_medium: 'средний',
  importance_high: 'высокий',
  importance_highest: 'очень высокий',
  complexity: 'сложность',
  complexity_short: 'c',

  more: 'ещё',
  urgency: 'срочность',
}
