export const auth = {
  loginOptions: {
    byPassword: 'По логину',
    bySMS: 'По номеру телефона',
    thirdParty: 'Внешние сервисы',
  },

  username: 'Имя пользователя',
  password: 'Пароль',
  canNotBeEmpty: 'Не может быть пустым',
  usernameOrPasswordError: 'Ошибка авторизации. Пожалуйста, проверьте правильно введённых логина и пароля.',
  stdError: 'Произошла ошибка, повторите попытку позже',

  title: 'Войти',
  description: 'Проверьте код страны и введите ваш номер телефона',
  descriptionEnterButton: 'Нажимая «{buttonLabel}», вы принимаете условия',
  logInDescription: 'Код активации отправлен на ваш телефон по SMS.\nПожалуйста, введите его ниже.',
  terms: 'Пользовательского соглашения',
  resentText: 'Повторный запрос кода возможен через ',
  resendedSmsText: 'Повторное смс с кодом отправлено',
  resendBtn: 'Отправить ещё раз',
  phoneInputPlaceholder: 'Код из смс',
  receivePassBtn: 'Выслать пароль',
  logInBtn: 'Войти',
  changePhoneBtn: 'Изменить номер',
  enterByPasswordButton: 'Войти',

  security: 'Безопасность',

  twoFactorAuth: {
    passDeleted: 'Пароль двухфакторной авторизации удалён',
    passEstablished: 'Пароль установлен',
    passChanged: 'Пароль изменён',
    passDrop: 'Сброс пароля',
    passDeleteApprove: 'Отключить пароль?',
    title: 'Двухфакторная авторизация',
    passDefaultLabel: 'Пароль должен содержать минимум 6 символов',
    passStepTitle: 'Придумайте пароль',
    mailStepTitle: 'Почта для восстановления',
    mailChangedTitle: 'Почта изменена',
    mailChanged: 'Электронная почта успешно изменена',
    mailCodeSent: 'На <b>{email}</b><br/>отправлен код подтверждения',
    mailCodeSentLogin: 'На <b>{email}</b> отправлен одноразовый код для сброса пароля',
    inputMailLabel: 'Укажите электронную почты для сброса пароля',
    noneMailWarning: 'Если вы не укажете почту и&nbsp;забудете пароль, вы&nbsp;потеряете доступ к&nbsp;своему аккаунту навсегда!',
    noneMailAcceptRisk: 'Я понимаю риск, {actionLabel}',
    removeMailAction: 'удалить почту',
    noneMailAction: 'продолжить без почты',
    emailTitle: 'Электронная почта',
    codeInputTitle: 'Проверочный код',
    codeSentAgain: 'Запросить код повторно можно через',
    sendAgain: 'Отправить ещё раз',
    changePass: 'Изменить пароль',
    discardPass: 'Удалить пароль',
    forgotPasswordQuestion: 'Забыли пароль?',
    changeEmail: 'Сменить почту',
    backToSettings: 'Вернуться к настройкам',
    continue: 'Продолжить',
    createPassBtn: 'Задать пароль',
    authEnabled: 'Подключена двухфакторная авторизация. Аккаунт защищён паролем',
    previewStepText: 'Установите дополнительный пароль, чтобы усилить защиту аккаунта. Его потребуется вводить при входе с любого устройства в дополнение к СМС-коду',
    defaultText: 'Пароль потребуется ввести при авторизации с нового устройства в дополнение СМС-коду',
    hintText: 'Поможет вспомнить пароль для входа',
    hintPlaceholder: 'Подсказка (необязательно)',
    repeat: 'Повторите',
    disablePass: 'Отключить',
    cancelRemove: 'Не отключать',
    hint: 'Подсказка',
    authDisableWarnText: 'Отключение двухфакторной авторизации снижает защиту вашего аккаунта',
    forgotPasswordError: 'Вы не указывали электронную почту для сброса пароля. Зайти в аккаунт можно только вспомнив пароль',
  },

  newLogin: {
    noPassword: 'Не введён пароль',
    noLogin: 'Не введён логин',
    noLoginAndPassword: 'Не введён логин и пароль',
    noCode: 'Не введён код',
    noPhone: 'Нет номера телефона',
    unknownSecondFactor: 'Неизвестный метод двухфакторной авторизации',
    usualServer: 'Облачный сервер',
    corpServer: 'Корпоративный сервер',
    connect: 'Подключиться',
    getCode: 'Получить код',
    authTitle: 'Войти',
    serverTitle: 'Адрес сервера',
    discardPassTitle: 'Сброс пароля',
    secondTitle: 'Дополнительный пароль',
    support: 'Поддержка',
    faq: 'Вопрос-ответ',
    phoneLabel: 'Выберите страну и&nbsp;введите номер',
    number: 'Номер',
    phone: 'Телефон',
    login: 'Логин',
    password: 'Пароль',
    chooseCountry: 'Выберите страну',
    termsText1: 'пользовательское соглашение',
    termsText2: 'политику конфиденциальности',
    checkSms: 'Проверьте код в&nbsp;СМС-сообщении',
    corpServerLabel: 'Введите адрес корпоративного сервера',
    tfaLoginLabel: 'Введите пароль от&nbsp;аккаунта:',
    onEmailCodeSent: 'На <b>{email}</b> отправлен код для восстановления доступа',
    emaily: 'почту',
    and: 'и',
  },

  newOnboarding: {
    teamCreation: 'Создать команду',
    teamEditing: 'Изменить команду',
    teamDataCanBeChanged: 'Вы всегда сможете переименовать команду и&nbsp;изменить аватар в&nbsp;её&nbsp;настройках',
    profileDataCanBeChanged: 'Вы&nbsp;всегда сможете изменить информацию о&nbsp;себе и&nbsp;аватар в&nbsp;настройках своего профиля',
    noTeamName: 'Введите название команды, максимум 200&nbsp;символов',
    aboutUser: 'Расскажите о&nbsp;себе',

    mobileLabel: 'Установите&nbsp;мобильное приложение&nbsp;tada.team и&nbsp;начните&nbsp;общение',
    mobileInstall: 'Установить',
    mobileToWeb: 'Продолжить в&nbsp;браузере',

    noInvitationsHeader: 'Нет приглашений',
    noInvitationsBody: 'Нет команд для вступления. Мы&nbsp;оповестим вас, как только они появятся',
    createTeam: 'Создать команду',
    addToTeamHeader: 'Пригласите коллег',
    addToTeamBody: 'Отправьте приглашения своим коллегам в&nbsp;команду <b>{teamName}</b>',
    addToTeamLabel: 'Вы всегда сможете пригласить своих коллег в&nbsp;команду после её&nbsp;создания',
    skipStep: 'Пропустить шаг',
    teamsManagmentHeader: 'Управление командами',
    teamsManagmentBody: 'Если ваша компания уже использует tada.team, мы&nbsp;поможем вам присоединиться к&nbsp;ней. Или создайте свою команду и&nbsp;пригласитe коллег',
    invitations: 'Приглашения',
    declineInvite: 'Отклонить',
    acceptedInviteBody: 'Приглашение принято. Перейдите в&nbsp;команду и&nbsp;начните общение',
    defaultInviteBody: 'Вас пригласили в команду </b>{teamName}.</b>\nПринять приглашение?',
    moveToTeam: 'Перейти в команду',
  },

  change_server: 'Поменять сервер',
  change_server_prompt: 'Вы уверены, что хотите поменять сервер? Доступ к текущему серверу будет доступен при нажатии на кнопку "Сервер" в меню приложения',
  change_server_confirm: 'Поменять',
  use_custom_server: 'Использовать сервер организации',
  newServerError: 'Адрес сервера мог быть указан с ошибкой. Проверьте указанный адрес или обратитесь к вашему системному администратору',
  sameServerError: 'Вы уже используете этот сервер',
  using_custom_server: 'Вы используете сервер организации',

  onboardProfile: {
    titleIsInvitation: 'Проверьте свои данные',
    title: 'Сперва, давайте познакомимся!',
    description: 'Другим участникам @:featuresTerms.teamR будет проще узнавать вас, если вы\nпоставите фото профиля и укажете имя и фамилию',
    uploadAvatarInfo: 'Перетащите сюда изображение или кликните на нем.',
    uploadAvatarFormat: 'Поддерживаемые форматы: png, jpg, jpeg, gif.',
    name: 'Имя',
    lastName: 'Фамилия',
    patronymic: 'Отчество',
    descInfo: {
      part1: 'Позднее эти и другие ваши данные можно будет',
      part2: 'изменить в настройках',
    },
    backCommandsBtn: 'Вернуться к списку @featuresTerms.teamsR',
    enterCommandBtn: 'Войти в @:featuresTerms.teamV',
    createCommandBtn: 'Перейти к созданию @:featuresTerms.teamR',
  },

  captcha: {
    errors: {
      loading: 'Ошибка загрузки reCAPTCHA',
      token: 'Ошибка получения токена reCAPTCHA',
    },
  },
}
