export const blocking = {
  title: 'Финансовая блокировка',
  description: 'Разблокировать команду <b>{teamName}</b>',
  description2: 'может её владелец — {ownerName}.',
  descriptionOwner: 'Вы являетесь владельцем команды <b>{teamName}</b>',
  descriptionOwner2: 'и можете разблокировать её в Личном кабинете.',
  send: 'Связаться с поддержкой',
  goToLK: 'Перейти в Личный кабинет',
  faq: 'Что такое Личный кабинет?',
  teamBlocked: 'Заблокирована',
  subjectLK: 'Финансовая блокировка ЛК ',
  activedTo: 'Активна до {day}',
}
