export const chattape = {
  openChatProfile: 'Открыть профиль',
  readOnly: 'Этот чат только для чтения',

  importantMessages: 'Важные сообщения',
  settings: 'Меню настроек',
  push: 'Push-уведомления',
  unreads: 'Cчётчик непрочитанных',
  replyTo: 'Ответ на',

  callDetails: 'Детали звонка',

  formatOptions: {
    bold: 'жирный',
    italic: 'курсив',
    underline: 'подчёркнутый',
    strike: 'зачёркнутый',
    quote: 'цитата',
    code: 'код',
    pre: 'блок\nтекста',
    link: {
      description: 'описание',
      protocol: 'https:',
      host: 'www.your.link',
    },
  },

  showMembers: 'Открыть список участников',
  sendFile: 'Отправить файл',
  sendTextBlock: 'Отправить блок текста',
  taskMessage: 'Написать в задачу #{num}',
  meetingMessage: 'Написать во встречу',
  groupMessage: 'Написать в чат {name}',
  directMessage: 'Написать контакту {name}',
  typeMessage: 'Написать сообщение',
  enableNtf: 'Включить уведомления',
  disableNtf: 'Выключить уведомления',

  selected: 'Выделено',

  seemsLikeMicBlocked: 'Ой, похоже, вы запретили использование микрофона',
  howToEnableMic: 'Как включить микрофон?',
  allowMicAccess: 'Разрешите доступ\nк микрофону',
  inSystemPreferences: ' в настройках системы и&nbsp;перезапустите приложение',
  shouldAllowMicAccess: 'Для записи аудиосообщения нужно\nразрешить доступ к микрофону',
  howToEnableMicAfterBlock: 'Как включить микрофон или камеру после блокировки',
  enableMicStepOne: 'Нажмите на микрофон {0} в правой части адресной строки.',
  enableMicStepTwo: 'Выберите опцию <b>Всегда предоставлять {url} доступ к&nbsp;моему&nbsp;микрофону</b> и&nbsp;нажмите кнопку <b>Готово</b>.',
  temporaryDisabled: 'Временно заблокировано',
  reloadPage: 'Обновите страницу.',
  clickIconOnTheLeft: 'Нажмите значок {0} в левой части адресной строки.',
  clickIconOnTheLeftMicro: 'Нажмите на микрофон {0} в левой части адресной строки.',
  wantSaveChoose: 'Если вы&nbsp;хотите, чтобы браузер запомнил ваш&nbsp;выбор и не&nbsp;спрашивал разрешение использовать микрофон при&nbsp;каждой отправке голосового сообщения, выберите опцию <b>Запомнить это решение</b>.',
  micModal: {
    ff: 'Удалите блокировку микрофона, нажмите значок {0} рядом с надписью {1}.',
    edge: 'При&nbsp;следующем запросе на&nbsp;доступ к&nbsp;микрофону выберите <b>Разрешить</b>.',
    opera: 'Выберите <b>Разрешить</b> напротив опции <b>Микрофон</b>.',
    safari_1: 'Выберите меню <b>Safari</b> > <b>Настройки для&nbsp;этого веб-сайта...</b>',
    safari_2: 'В&nbsp;открывшемся окне напротив <b>Микрофона</b> выберите вариант <b>Разрешить</b>.',
    yandex: 'Включите опцию <b>Использовать микрофон</b>.',
    mac: {
      system: 'В&nbsp;<b>Системных настройках</b> выберите <b>Защита и безопасность</b>.',
      confid: 'В&nbsp;открывшемся окне нажмите <b>Конфиденциальность</b>.',
      micro: 'В&nbsp;списке слева выберите <b>Микрофон</b> или <b>Камера</b>.',
      checkMark: 'В&nbsp;списке справа найдите <b>tada.team</b> и поставьте галочку напротив названия.',
    },
    win: {
      search: 'Кликните&nbsp;на поиск&nbsp;и напишите микрофон.',
      select: 'Выберите <b>Отключение доступа к&nbsp;микрофону для&nbsp;всех приложений</b>.',
      micro: 'В&nbsp;появившемся окне включите оба переключателя <b>Разрешить приложениям доступ к&nbsp;микрофону</b> и <b>Разрешить классическим приложениям доступ к&nbsp;микрофону</b>.',
      reload: 'Проверьте в&nbsp;этом&nbsp;же окне, что в&nbsp;списке приложений есть <b>tada.team</b>. Если&nbsp;нет&nbsp;—&nbsp;перезагрузите компьютер.',
    },
  },

  directChat: 'Личный чат',
  messageFromDirect: 'Личное сообщение',
  messageFromGroup: 'Сообщение из чата',
  messageFromTask: 'Сообщение из задачи',

  unreadMessages: 'Непрочитанные сообщения',

  messageActions: {
    resend: 'Отправить еще раз',
    task: '@:common.createTask',
    taskItem: 'Добавить в чек-лист',
    meeting: 'Создать встречу',
    hidePreview: 'Убрать предпросмотр',
    'smile-o': 'Добавить реакцию',
    reply: 'Ответить',
    forward: 'Переслать',
    forwardHere: 'Переслать сюда',
    copy: 'Скопировать текст',
    important: 'Отметить как важное',
    notImportant: 'Убрать из важных',
    edit: 'Изменить',
    delete: 'Удалить для всех',
    debug: 'Пообщаться с жучарой',
    trash: '@:chattape.messageActions.delete',
    pin: 'Закрепить сообщение',
    unpin: 'Открепить сообщение',
    comment: 'Комментировать',
  },
  isBot: 'Бот',

  importantHook: {
    curChat: 'Только текущий чат',
    notFound: 'Не найдено ни одного сообщения',
  },

  mainHookBtn: 'Перейти к последним сообщениям',
  stickers: '@:(glossary.sticker_plural)',
  attachFile: 'Прикрепить файл',
  userRecAudioMsg: 'записывает аудиосообщение...',
  userRecAudioMsgShort: 'записывает аудио...',
  userRecAudioMsgShort_plural: 'записывают аудио...',
  openedGroup: 'Открытая группа',
  publicTask: 'Публичная задача',
  publicMeeting: 'Публичная встреча',
  groupListOpen: 'Открыть список @:featuresTerms.teamsR',
  groupListHide: 'Закрыть список @:featuresTerms.teamsR',
  openInNewWindow: 'Открыть в отдельном окне',

  selectedMessageBar: {
    canForwardMessages: 'Нельзя пересылать больше {max} сообщений',
    cannotDelete: 'Нельзя удалять чужие или слишком старые сообщения',
  },

  changeMessage: {
    moreInfo: 'Подробнее... ',
    emptyField: 'НЕТ',
  },

  untitledFile: 'Без названия',
  textBlockExpand: 'Раскрыть',
  textBlockCollapse: 'Свернуть',

  reactionRecentlyUsed: 'Последние использованные',
  reactionAll: 'Эмоции',
  reactionAdd: 'Добавить еще реакцию',

  deleteMessagesTitle: 'Удаление сообщений',
  deleteMessagesText: 'Вы уверены, что хотите удалить выбранные сообщения для всех?',
  deleteOneMessageTitle: 'Удаление сообщения',
  deleteOneMessageText: 'Вы уверены, что хотите удалить это сообщение для всех?',

  messageIsEdited: '(ред.)',

  forwardedMessages_0: 'пересланное сообщение',
  forwardedMessages_1: 'пересланных сообщения',
  forwardedMessages_2: 'пересланных сообщений',

  dropTaskHint: 'Отпустите клавишу мыши, чтобы отправить ссылку на задачу',
  dropFileHint: 'Отпустите клавишу мыши, чтобы загрузить файл',

  goToMessage: {
    title: 'Переход в чат',
    textHTML: 'Вы уверены, что хотите перейти в чат <b>{sourceName}</b> к этому сообщению?',
    go: 'Перейти',
  },

  emoji: {
    smileysPeople: 'Смайлы и люди',
    animalsNature: 'Животные и природа',
    objects: 'Предметы',
    foodDrink: 'Еда и напитки',
    activitySport: 'Хобби и спорт',
    travelPlaces: 'Транспорт и путешествия',
  },
  sendMessage: {
    title: 'Отправка сообщения:',
    enterLine1: 'Enter – отправить сообщение',
    enterLine2: 'Shift + Enter – новая строка',
    ctrlEnterLine1: 'Ctrl + Enter, Cmd + Enter – отправить сообщение',
    ctrlEnterLine2: 'Enter – новая строка',
  },

  file: {
    open: 'Открыть',
    preview: 'Просмотреть',
    retry: 'Повторить',
    showInFinder: 'Показать в Finder',
    showInFolder: 'Показать в папке',
  },
}
