export const dashboard = {
  groupBy: 'Группировать по',
  sortBy: 'Сортировать по',
  filter: 'Фильтр',
  assigneeMe: 'Я исполнитель',
  clear: 'Очистить',
  chooseAssignee: 'Выберите исполнителя:',
  chooseOwner: 'Выберите постановщика:',
  chooseTag: 'Выберите тег',
  viewOptions: {
    byProject: 'Проект',
    byAssignee: 'Исполнитель',
    byOwner: 'Постановщик',
    byTag: 'Тег',
    byStatus: 'Статус',
    byImportance: 'Приоритет',
    byDepartment: 'Отдел',
  },
  sortingOptions: {
    byActivity: 'По активности',
    byDeadline: 'По сроку выполнения',
    newFirst: 'Сначала новые',
    oldFirst: 'Сначала старые',
    byImportance: 'По приоритету',
  },
  filterOptions: {
    status: '@:glossary.status',
    assignee: '@:dashboard.viewOptions.byAssignee',
    assignee_plural: 'Исполнители',
    owner: '@:dashboard.viewOptions.byOwner',
    owner_plural: 'Постановщики',
    tag: '@:dashboard.viewOptions.byTag',
    tag_plural: '@:glossary.tag_plural',
    allTasks: 'Все задачи',
    onlyActive: 'Активные',
    onlyDone: 'Завершённые',
    inbox: 'Входящие',
    outbox: 'Исходящие',
  },
  finished: 'Завершена',
  thisWeek: 'На этой неделе',
  nextWeek: 'На следующей неделе',
  thisMonth: 'В этом месяце',
  nextMonth: 'В следующем месяце',
  project: 'Проект',
  notAuthorized: 'Обратитесь к администратору @:featuresTerms.teamR для изменения и добавления правил',
  noTaskChangeRights_section: 'Вы не можете изменять @:glossary.projectV в задаче #{num}',
  noTaskChangeRights_tags: 'Вы не можете изменять @:glossary.tagV_plural в задаче #{num}',
  noTaskChangeRights_assignee: 'Вы не можете изменять @:glossary.assigneeV в задаче #{num}',
  noTaskChangeRights_task_status: 'Вы не можете изменять @:glossary.statusV в задаче #{num}',
  noTaskChangeRights_owner: 'Вы не можете изменять @:glossary.ownerV в задаче #{num}',
  noTasksYet: 'У вас пока нет задач',
  newColumnTooltip: {
    title: 'Этой колонки раньше не было на доске задач.',
    action: 'Нажмите на иконку, чтобы зафиксировать эту колонку здесь, или перетащите её куда вам удобно.',
    caption: 'Вы всегда можете поменять расположение колонок позже.',
  },
  nowReordable: {
    columns: 'Колонки теперь можно перетаскивать. Попробуйте!',
  },
  moveTo: {
    start: 'Перенести в начало',
    end: 'Перенести в конец',
  },
}
